// ... (other imports)
import React, { useEffect, useState, useContext } from "react";
import api from "../../components/api/api";
import style from "./style.module.css";
import Footer from "../../components/Footer/index";
import Lateral from "../../components/Lateral/index";
import CircularProgress from "@mui/material/CircularProgress";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, EffectFade } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { OddsContext } from "../../components/context";
import { auth, onAuthStateChanged } from "../../components/firebase/firebase";
import betLogos from "../../assets/betLogos";
import carrossel1 from "../../assets/BANNER_1.png";
import carrossel2 from "../../assets/BANNER_2.png";
import carrossel3 from "../../assets/BANNER_3.png";
import ad from "../../assets/3.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/swiper-bundle.css";
import "swiper/css/effect-fade";

const data = [
  { id: "1", image: carrossel3 },
  { id: "2", image: carrossel2 },
  { id: "3", image: carrossel1 },
];

const betLogoMap = {
  bet365: betLogos.bet365,
  betano: betLogos.betano,
  betway: betLogos.betway,
  rivalry: betLogos.rivalry,
  sportingbet: betLogos.sportingbet,
  vaidebet: betLogos.vaidebet,
  pixbet: betLogos.pixbet,
  x1bet: betLogos.x1bet,
  casadeapostas: betLogos.casadeapostas,
  betmais: betLogos.betmais,
  esportiva: betLogos.esportivabet,
  brisabet: betLogos.brisabet,
  kto_group: betLogos.kto_group,
  brazino777: betLogos.brazino,
  k7bet: betLogos.bet7k,
  bet_vip: betLogos.betvip,
  sorte_online: betLogos.sorte_Online,
};

function Clicada() {
  const navigate = useNavigate();
  const { atualizaodd, setAtualizaodd, oddClicada, setOddClicada } =
    useContext(OddsContext);

  const [slidesPerView, setSlidesPerView] = useState(2);
  const [odd, setOdd] = useState([]);
  const [nomeFora, setNomeFora] = useState("");
  const [nomeCasa, setNomeCasa] = useState("");
  const [imgFora, setImgFora] = useState("");
  const [imgCasa, setImgCasa] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("casa_empate_fora");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  // State to store grouped handicap data
  const [groupedHandicapRows, setGroupedHandicapRows] = useState({});

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) navigate("/login");
    });

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getOddClicada();
  }, [atualizaodd, oddClicada, selectedOption]);

  const handleResize = () => {
    setSlidesPerView(window.innerWidth < 768 ? 1 : 2);
  };

  const getOddClicada = async () => {
    setNomeFora(localStorage.getItem("nomefora"));
    setNomeCasa(localStorage.getItem("nomecasa"));
    setImgFora(localStorage.getItem("logofora"));
    setImgCasa(localStorage.getItem("logocasa"));

    try {
      setIsLoading(true);
      const response = await api.get(
        `/bets/${localStorage.getItem("id")}/${selectedOption}_odd`
      );
      setOdd(response.data.data);
      setIsLoading(false);

      if (selectedOption === "handicap") {
        processHandicapData(response.data.data);
      }
    } catch (error) {
      alert("Erro ao buscar odds.");
    }
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setOddClicada(option);
    setSortColumn(null);
    setSortDirection("asc");
  };

  const handleSort = (header) => {
    if (sortColumn === header) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(header);
      setSortDirection("asc");
    }
  };

  const processHandicapData = (oddData) => {
    const groupedHandicapRowsTemp = {};
    const maxOddsPerHandicap = {};

    oddData.forEach((oddItem) => {
      const bethouseName = oddItem.bethouse.nome;
      const bethouseUrl = oddItem.bethouse.bethouse_url;
      const bethouseLogo = betLogoMap[bethouseName];

      if (oddItem.HandicapOdd) {
        Object.entries(oddItem.HandicapOdd).forEach(([key, value]) => {
          const oddValue = Number(value);
          if (oddValue !== 0) {
            const handicapLabel = getHandicapLabel(key);
            if (!groupedHandicapRowsTemp[handicapLabel]) {
              groupedHandicapRowsTemp[handicapLabel] = [];
              maxOddsPerHandicap[handicapLabel] = oddValue;
            } else {
              if (oddValue > maxOddsPerHandicap[handicapLabel]) {
                maxOddsPerHandicap[handicapLabel] = oddValue;
              }
            }
            groupedHandicapRowsTemp[handicapLabel].push({
              bettingHouseName: bethouseName,
              bettingHouseUrl: bethouseUrl,
              bettingHouseLogo: bethouseLogo,
              handicapKey: key,
              handicapLabel: handicapLabel,
              oddValue: oddValue,
            });
          }
        });
      }
    });

    // Store the max odds per handicap
    setGroupedHandicapRows({
      data: groupedHandicapRowsTemp,
      maxOdds: maxOddsPerHandicap,
    });
  };

  const getHandicapLabel = (key) => {
    const parts = key.split("_");
    const team = parts[0]; // 'casa' or 'fora'
    const sign = parts[1]; // 'plus' or 'minus'
    const valuePart = parts[2]; // e.g., '40', '35', etc.
    const teamLabel = team === "casa" ? nomeCasa : nomeFora;
    const signLabel = sign === "plus" ? "+" : "-";
    let value;

    if (valuePart.length === 2) {
      value = valuePart.slice(0, -1) + "." + valuePart.slice(-1);
    } else if (valuePart.length === 1) {
      value = "0." + valuePart;
    } else {
      value = valuePart;
    }

    return `${teamLabel} ${signLabel}${value}`;
  };

  const extractHandicapValue = (handicapLabel) => {
    const parts = handicapLabel.split(" ");
    const signValue = parts[1]; // e.g., '+1.5' or '-0.5'
    return parseFloat(signValue);
  };

  const renderColumnHeaders = () => {
    if (selectedOption === "handicap") {
      return (
        <>
          <th>Casa de Aposta</th>
          <th>Handicap</th>
          <th>Odd</th>
        </>
      );
    }

    // Existing code for other options
    const headersMap = {
      casa_empate_fora: ["Casa de aposta", "Casa", "Empate", "Fora"],
      ambos_marcam: ["Casa de aposta", "Sim", "Não"],
      over_under_gols: ["Casa de aposta", "0.5+", "1.5+", "0.5-", "1.5-"],
      dupla_chance: [
        "Casa de aposta",
        "Casa ou Empate",
        "Casa ou Fora",
        "Fora ou Empate",
      ],
    };

    return headersMap[selectedOption]?.map((header) => (
      <th key={header} onClick={() => handleSort(header)}>
        {header}
        {sortColumn === header
          ? sortDirection === "asc"
            ? " ▲"
            : " ▼"
          : " ▲▼"}
      </th>
    ));
  };

  const renderBetRows = () => {
    if (selectedOption === "handicap") {
      const { data: groupedData, maxOdds: maxOddsPerHandicap } =
        groupedHandicapRows;

      // Check if groupedData is defined
      if (!groupedData || Object.keys(groupedData).length === 0) {
        return (
          <tr>
            <td colSpan="3">No data available</td>
          </tr>
        );
      }

      const handicapEntries = Object.entries(groupedData);

      // Sort the handicap entries based on the handicap value
      handicapEntries.sort((a, b) => {
        const valueA = extractHandicapValue(a[0]);
        const valueB = extractHandicapValue(b[0]);
        return valueA - valueB;
      });

      return (
        <>
          {handicapEntries.map(([handicapLabel, rows], index) => {
            // Sort rows within each handicap by oddValue descending
            const sortedRows = rows.sort((a, b) => b.oddValue - a.oddValue);
            const maxOdd = maxOddsPerHandicap[handicapLabel];

            return (
              <React.Fragment key={index}>
                {/* Betting House Rows */}
                {sortedRows.map((row, idx) => (
                  <tr key={`${index}-${idx}`} className={style.betRow}>
                    <td>
                      <img
                        src={row.bettingHouseLogo}
                        alt={row.bettingHouseName}
                        className={style.logobet}
                      />
                    </td>
                    <td>{row.handicapLabel}</td>
                    <td>
                      {renderButton(
                        row.bettingHouseUrl,
                        row.oddValue.toFixed(2),
                        row.oddValue === maxOdd,
                        row.oddValue
                      )}
                    </td>
                  </tr>
                ))}
                {/* Divider */}
                <tr className={style.dividerRow}>
                  <td colSpan="3" className={style.dividerCell}></td>
                </tr>
              </React.Fragment>
            );
          })}
        </>
      );
    }

    // Existing code for other options

    // Calculate max odds for other options
    const maxValues = calculateMaxOdds(odd);

    const sortedOdds = [...odd].sort((a, b) => {
      const valueA = getSortValue(a);
      const valueB = getSortValue(b);

      if (isNaN(valueA) && isNaN(valueB)) return 0;
      if (isNaN(valueA)) return 1;
      if (isNaN(valueB)) return -1;

      if (sortDirection === "asc") {
        return valueA - valueB;
      } else {
        return valueB - valueA;
      }
    });

    return sortedOdds.map((oddItem, index) => (
      <tr key={index} className={style.betRow}>
        <td>
          <img
            src={betLogoMap[oddItem.bethouse.nome]}
            alt="Logo"
            className={style.logobet}
          />
        </td>

        {selectedOption === "casa_empate_fora" && oddItem.CasaEmpateForaOdd && (
          <>
            <td>
              {renderButton(
                oddItem.bethouse.bethouse_url,
                Number(oddItem.CasaEmpateForaOdd.casa_at_odd).toFixed(2),
                Number(oddItem.CasaEmpateForaOdd.casa_at_odd) ===
                  maxValues.maxCasa,
                Number(oddItem.CasaEmpateForaOdd.casa_at_odd)
              )}
            </td>
            <td>
              {renderButton(
                oddItem.bethouse.bethouse_url,
                Number(oddItem.CasaEmpateForaOdd.empate_at_odd).toFixed(2),
                Number(oddItem.CasaEmpateForaOdd.empate_at_odd) ===
                  maxValues.maxEmpate,
                Number(oddItem.CasaEmpateForaOdd.empate_at_odd)
              )}
            </td>
            <td>
              {renderButton(
                oddItem.bethouse.bethouse_url,
                Number(oddItem.CasaEmpateForaOdd.fora_at_odd).toFixed(2),
                Number(oddItem.CasaEmpateForaOdd.fora_at_odd) ===
                  maxValues.maxFora,
                Number(oddItem.CasaEmpateForaOdd.fora_at_odd)
              )}
            </td>
          </>
        )}

        {selectedOption === "ambos_marcam" && oddItem.AmbosMarcamOdd && (
          <>
            <td>
              {renderButton(
                oddItem.bethouse.bethouse_url,
                Number(oddItem.AmbosMarcamOdd.sim_at_odd).toFixed(2),
                Number(oddItem.AmbosMarcamOdd.sim_at_odd) === maxValues.maxSim,
                Number(oddItem.AmbosMarcamOdd.sim_at_odd)
              )}
            </td>
            <td>
              {renderButton(
                oddItem.bethouse.bethouse_url,
                Number(oddItem.AmbosMarcamOdd.nao_at_odd).toFixed(2),
                Number(oddItem.AmbosMarcamOdd.nao_at_odd) === maxValues.maxNao,
                Number(oddItem.AmbosMarcamOdd.nao_at_odd)
              )}
            </td>
          </>
        )}

        {selectedOption === "over_under_gols" && oddItem.OverUnderGolsOdd && (
          <>
            <td>
              {renderButton(
                oddItem.bethouse.bethouse_url,
                Number(oddItem.OverUnderGolsOdd.over_05_at_odd).toFixed(2),
                Number(oddItem.OverUnderGolsOdd.over_05_at_odd) ===
                  maxValues.maxOver05,
                Number(oddItem.OverUnderGolsOdd.over_05_at_odd)
              )}
            </td>
            <td>
              {renderButton(
                oddItem.bethouse.bethouse_url,
                Number(oddItem.OverUnderGolsOdd.over_15_at_odd).toFixed(2),
                Number(oddItem.OverUnderGolsOdd.over_15_at_odd) ===
                  maxValues.maxOver15,
                Number(oddItem.OverUnderGolsOdd.over_15_at_odd)
              )}
            </td>
            <td>
              {renderButton(
                oddItem.bethouse.bethouse_url,
                Number(oddItem.OverUnderGolsOdd.under_05_at_odd).toFixed(2),
                Number(oddItem.OverUnderGolsOdd.under_05_at_odd) ===
                  maxValues.maxUnder05,
                Number(oddItem.OverUnderGolsOdd.under_05_at_odd)
              )}
            </td>
            <td>
              {renderButton(
                oddItem.bethouse.bethouse_url,
                Number(oddItem.OverUnderGolsOdd.under_15_at_odd).toFixed(2),
                Number(oddItem.OverUnderGolsOdd.under_15_at_odd) ===
                  maxValues.maxUnder15,
                Number(oddItem.OverUnderGolsOdd.under_15_at_odd)
              )}
            </td>
          </>
        )}

        {selectedOption === "dupla_chance" && oddItem.DuplaChanceOdd && (
          <>
            <td>
              {renderButton(
                oddItem.bethouse.bethouse_url,
                Number(oddItem.DuplaChanceOdd.casa_empate_at_odd).toFixed(2),
                Number(oddItem.DuplaChanceOdd.casa_empate_at_odd) ===
                  maxValues.maxCasaEmpate,
                Number(oddItem.DuplaChanceOdd.casa_empate_at_odd)
              )}
            </td>
            <td>
              {renderButton(
                oddItem.bethouse.bethouse_url,
                Number(oddItem.DuplaChanceOdd.casa_fora_at_odd).toFixed(2),
                Number(oddItem.DuplaChanceOdd.casa_fora_at_odd) ===
                  maxValues.maxCasaFora,
                Number(oddItem.DuplaChanceOdd.casa_fora_at_odd)
              )}
            </td>
            <td>
              {renderButton(
                oddItem.bethouse.bethouse_url,
                Number(oddItem.DuplaChanceOdd.empate_fora_at_odd).toFixed(2),
                Number(oddItem.DuplaChanceOdd.empate_fora_at_odd) ===
                  maxValues.maxEmpateFora,
                Number(oddItem.DuplaChanceOdd.empate_fora_at_odd)
              )}
            </td>
          </>
        )}
      </tr>
    ));
  };

  const renderButton = (url, label, isMax, oddValue) => {
    const formattedLabel = Number(oddValue) === 0 ? "-" : label;
    const buttonClass =
      Number(oddValue) === 0
        ? style.betButton
        : `${style.betButton} ${isMax ? style.maxOddButton : ""}`;

    return (
      <a href={url} target="_blank" rel="noreferrer">
        <button className={buttonClass}>{formattedLabel}</button>
      </a>
    );
  };

  const getSortValue = (oddItem) => {
    if (selectedOption === "casa_empate_fora") {
      if (sortColumn === "Casa")
        return Number(oddItem.CasaEmpateForaOdd?.casa_at_odd) || 0;
      if (sortColumn === "Empate")
        return Number(oddItem.CasaEmpateForaOdd?.empate_at_odd) || 0;
      if (sortColumn === "Fora")
        return Number(oddItem.CasaEmpateForaOdd?.fora_at_odd) || 0;
    } else if (selectedOption === "ambos_marcam") {
      if (sortColumn === "Sim")
        return Number(oddItem.AmbosMarcamOdd?.sim_at_odd) || 0;
      if (sortColumn === "Não")
        return Number(oddItem.AmbosMarcamOdd?.nao_at_odd) || 0;
    } else if (selectedOption === "over_under_gols") {
      if (sortColumn === "0.5+")
        return Number(oddItem.OverUnderGolsOdd?.over_05_at_odd) || 0;
      if (sortColumn === "1.5+")
        return Number(oddItem.OverUnderGolsOdd?.over_15_at_odd) || 0;
      if (sortColumn === "0.5-")
        return Number(oddItem.OverUnderGolsOdd?.under_05_at_odd) || 0;
      if (sortColumn === "1.5-")
        return Number(oddItem.OverUnderGolsOdd?.under_15_at_odd) || 0;
    } else if (selectedOption === "dupla_chance") {
      if (sortColumn === "Casa ou Empate")
        return Number(oddItem.DuplaChanceOdd?.casa_empate_at_odd) || 0;
      if (sortColumn === "Casa ou Fora")
        return Number(oddItem.DuplaChanceOdd?.casa_fora_at_odd) || 0;
      if (sortColumn === "Fora ou Empate")
        return Number(oddItem.DuplaChanceOdd?.empate_fora_at_odd) || 0;
    }
    return 0;
  };

  const calculateMaxOdds = (oddsArray) => {
    let maxCasa = -Infinity,
      maxEmpate = -Infinity,
      maxFora = -Infinity,
      maxSim = -Infinity,
      maxNao = -Infinity,
      maxOver05 = -Infinity,
      maxOver15 = -Infinity,
      maxUnder05 = -Infinity,
      maxUnder15 = -Infinity,
      maxCasaEmpate = -Infinity,
      maxCasaFora = -Infinity,
      maxEmpateFora = -Infinity;

    oddsArray.forEach((oddItem) => {
      if (selectedOption === "casa_empate_fora" && oddItem.CasaEmpateForaOdd) {
        maxCasa = Math.max(
          maxCasa,
          Number(oddItem.CasaEmpateForaOdd.casa_at_odd)
        );
        maxEmpate = Math.max(
          maxEmpate,
          Number(oddItem.CasaEmpateForaOdd.empate_at_odd)
        );
        maxFora = Math.max(
          maxFora,
          Number(oddItem.CasaEmpateForaOdd.fora_at_odd)
        );
      } else if (selectedOption === "ambos_marcam" && oddItem.AmbosMarcamOdd) {
        maxSim = Math.max(maxSim, Number(oddItem.AmbosMarcamOdd.sim_at_odd));
        maxNao = Math.max(maxNao, Number(oddItem.AmbosMarcamOdd.nao_at_odd));
      } else if (
        selectedOption === "over_under_gols" &&
        oddItem.OverUnderGolsOdd
      ) {
        maxOver05 = Math.max(
          maxOver05,
          Number(oddItem.OverUnderGolsOdd.over_05_at_odd)
        );
        maxOver15 = Math.max(
          maxOver15,
          Number(oddItem.OverUnderGolsOdd.over_15_at_odd)
        );
        maxUnder05 = Math.max(
          maxUnder05,
          Number(oddItem.OverUnderGolsOdd.under_05_at_odd)
        );
        maxUnder15 = Math.max(
          maxUnder15,
          Number(oddItem.OverUnderGolsOdd.under_15_at_odd)
        );
      } else if (selectedOption === "dupla_chance" && oddItem.DuplaChanceOdd) {
        maxCasaEmpate = Math.max(
          maxCasaEmpate,
          Number(oddItem.DuplaChanceOdd.casa_empate_at_odd)
        );
        maxCasaFora = Math.max(
          maxCasaFora,
          Number(oddItem.DuplaChanceOdd.casa_fora_at_odd)
        );
        maxEmpateFora = Math.max(
          maxEmpateFora,
          Number(oddItem.DuplaChanceOdd.empate_fora_at_odd)
        );
      }
    });

    return {
      maxCasa,
      maxEmpate,
      maxFora,
      maxSim,
      maxNao,
      maxOver05,
      maxOver15,
      maxUnder05,
      maxUnder15,
      maxCasaEmpate,
      maxCasaFora,
      maxEmpateFora,
    };
  };

  return (
    <div>
      <div className={style.divcarrossel}>
        <div className={style.carrossel}>
          <Swiper
            modules={[Navigation, Pagination, A11y, EffectFade]}
            slidesPerView={1}
            pagination={{ clickable: true }}
            loop={true}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
          >
            {data.map((item) => (
              <SwiperSlide key={item.id}>
                <img
                  src={item.image}
                  alt="Slider"
                  className={style.sliderItem}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className={style.geral}>
        <Lateral />
        <div className={style.main}>
          {isLoading ? (
            <div className="loading-bar">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className={style.headerBet}>
                <div className={style.divHeaderBet}>
                  <img
                    src={imgCasa}
                    alt="Team Logo"
                    className={style.teamLogo}
                  />
                  <p className={style.nomeCasa}>{nomeCasa}</p>
                  <p>vs</p>
                  <p className={style.nomeFora}>{nomeFora}</p>
                  <img
                    src={imgFora}
                    alt="Team Logo"
                    className={style.teamLogo}
                  />
                </div>
              </div>

              <div className={style.buttonGroup}>
                {[
                  "casa_empate_fora",
                  "ambos_marcam",
                  "over_under_gols",
                  "dupla_chance",
                  "handicap",
                ].map((option) => (
                  <button
                    key={option}
                    className={
                      selectedOption === option
                        ? style.activeButton
                        : style.inactiveButton
                    }
                    onClick={() => handleOptionChange(option)}
                  >
                    {getOptionLabel(option)}
                  </button>
                ))}
              </div>

              <div className={style.bets}>
                <table className={style.betTable}>
                  <thead>
                    <tr>{renderColumnHeaders()}</tr>
                  </thead>
                  <tbody>{renderBetRows()}</tbody>
                </table>
              </div>
            </>
          )}
        </div>
        <div className={style.ad}>
          <img src={ad} alt="Ad" />
        </div>
      </div>

      <Footer />
    </div>
  );
}

const getOptionLabel = (option) => {
  const labels = {
    casa_empate_fora: "1X2",
    ambos_marcam: "AMBOS MARCAM",
    over_under_gols: "TOTAL DE GOLS",
    dupla_chance: "DUPLA CHANCE",
    handicap: "HANDICAP",
    handicap_asiatico: "HANDICAP ASIÁTICO",
  };
  return labels[option] || "";
};

export default Clicada;
