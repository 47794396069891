// src/components/OddDetails.js
import React from "react";
import style from "./OddDetails.module.css";
import bet365 from "../../assets/bet365.png";
import betano from "../../assets/Betano.webp";
import betway from "../../assets/betway.png";
import rivalry from "../../assets/rivalry.png";
import sportingbet from "../../assets/sportingbet.png";
import vaidebet from "../../assets/vaidebet.png";
import pixbet from "../../assets/pixbet.png";
import casadeapostas from "../../assets/image.png";
import x1bet from "../../assets/1xbet.png";
import betmais from "../../assets/betmais.png";
import brisabet from "../../assets/brisabet.png";
import esportivabet from "../../assets/esportivabet.png";
import kto from "../../assets/kto.png";
import brazino from "../../assets/brazino.webp";
import bet7k from "../../assets/bet7k.png";
import betvip from "../../assets/betvip.png";
import sorte_Online from "../../assets/sorteonline.png";

const OddDetails = ({ oddClicada, oddcolor, HandleClick, selectedOption }) => {
  console.log(oddClicada);
  return (
    <div className={style.cardOddClicada}>
      {Array.isArray(oddClicada) &&
        oddClicada.map((odd, index) => ( 
          <div key={index} className={style.divoddclicada}>
            {/* Exibição das logos com base na casa de apostas */}
            {odd.bethouse_nome === "bet365" && <img src={bet365} alt="Logo" className={style.logobet} />}
            {odd.bethouse_nome === "betano" && <img src={betano } alt="Logo" className={style.logobet} />}
            {odd.bethouse_nome === "betway" && <img src={betway} alt="Logo" className={style.logobet} />}
            {odd.bethouse_nome === "rivalry" && <img src={rivalry} alt="Logo" className={style.logobet} />}
            {odd.bethouse_nome === "sportingbet" && <img src={sportingbet} alt="Logo" className={style.logobet} />}
            {odd.bethouse_nome === "vaidebet" && <img src={vaidebet} alt="Logo" className={style.logobet} />}
            {odd.bethouse_nome === "pixbet" && <img src={pixbet} alt="Logo" className={style.logobet} />}
            {odd.bethouse_nome === "casadeapostas" && <img src={casadeapostas} alt="Logo" className={style.logobet} />}
            {odd.bethouse_nome === "x1bet" && <img src={x1bet} alt="Logo" className={style.logobet} />}
            {odd.bethouse_nome === "betmais" && <img src={betmais} alt="Logo" className={style.logobet} />}
            {odd.bethouse_nome === "brisabet" && <img src={brisabet} alt="Logo" className={style.logobet} />}
            {odd.bethouse_nome === "esportiva" && <img src={esportivabet} alt="Logo" className={style.logobet} />}
            {odd.bethouse_nome === "kto_group" && <img src={kto} alt="Logo" className={style.logobet} />}
            {odd.bethouse_nome === "brazino777" && <img src={brazino} alt="Logo" className={style.logobet} />}
            {odd.bethouse_nome === "sorte_online" && <img src={sorte_Online} alt="Logo" className={style.logobet} />}
            {odd.bethouse_nome === "k7bet" && <img src={bet7k} alt="Logo" className={style.logobet} />}
            {odd.bethouse_nome === "bet_vip" && <img src={betvip} alt="Logo" className={style.logobet} />}

            {/* Exibir times */}
            {/* <p className={style.timesclicado}>
              {odd.time_casa} x {odd.time_fora}
            </p> */}
            
            {/* Verifica a opção selecionada e renderiza as odds correspondentes */}
            {selectedOption === "option1" && (
              <div>
                {/* Casa */}
                {odd.casa_at_odd !== 0 && (
                  <a href={`${odd.bethouse_site_url}`} target="_blank" rel="noreferrer">
                    <button
                      onClick={() => HandleClick(odd.bethouse_site_url)}
                      className={style.button4}
                      style={{
                        backgroundColor: oddcolor === "casa" ? "#0419E5" : "",
                        color: oddcolor === "casa" ? "#fff" : "",
                      }}
                    >
                      {Number(odd.casa_at_odd).toFixed(2)}
                    </button>
                  </a>
                )}
                {/* Empate */}
                {odd.empate_at_odd !== 0 && (
                  <a href={`${odd.bethouse_site_url}`} target="_blank" rel="noreferrer">
                    <button
                      onClick={() => HandleClick(odd.bethouse_site_url)}
                      className={style.button5}
                      style={{
                        backgroundColor: oddcolor === "empate" ? "#0419E5" : "",
                        color: oddcolor === "empate" ? "#fff" : "",
                      }}
                    >
                      {Number(odd.empate_at_odd).toFixed(2)}
                    </button>
                  </a>
                )}
                {/* Fora */}
                {odd.fora_at_odd !== 0 && (
                  <a href={`${odd.bethouse_site_url}`} target="_blank" rel="noreferrer">
                    <button
                      onClick={() => HandleClick(odd.bethouse_site_url)}
                      className={style.button6}
                      style={{
                        backgroundColor: oddcolor === "fora" ? "#0419E5" : "",
                        color: oddcolor === "fora" ? "#fff" : "",
                      }}
                    >
                      {Number(odd.fora_at_odd).toFixed(2)}
                    </button>
                  </a>
                )}
              </div>
            )}

            {/* Ambos Marcam */}
            {selectedOption === "option2" && (
              <div>
                {/* Ambos Marcam Sim */}
                {odd.ambos_marcam_sim_maior_odd !== 0 && (
                  <a href={`${odd.bethouse_site_url}`} target="_blank" rel="noreferrer">
                    <button
                      onClick={() => HandleClick(odd.bethouse_site_url)}
                      className={style.button4}
                      style={{
                        backgroundColor: oddcolor === "ambos_marcam_sim" ? "#0419E5" : "",
                        color: oddcolor === "ambos_marcam_sim" ? "#fff" : "",
                      }}
                    >
                      {Number(odd.ambos_marcam_sim_maior_odd).toFixed(2)}
                    </button>
                  </a>
                )}
                {/* Ambos Marcam Não */}
                {odd.ambos_marcam_nao_maior_odd !== 0 && (
                  <a href={`${odd.bethouse_site_url}`} target="_blank" rel="noreferrer">
                    <button
                      onClick={() => HandleClick(odd.bethouse_site_url)}
                      className={style.button5}
                      style={{
                        backgroundColor: oddcolor === "ambos_marcam_nao" ? "#0419E5" : "",
                        color: oddcolor === "ambos_marcam_nao" ? "#fff" : "",
                      }}
                    >
                      {Number(odd.ambos_marcam_nao_maior_odd).toFixed(2)}
                    </button>
                  </a>
                )}
              </div>
            )}

            {/* Odds de Gols */}
            {selectedOption === "option3" && (
              <div>
                {/* Gols Menos de 1.5 */}
                {odd.casa_empate_maior_odd !== 0 && (
                  <a href={`${odd.bethouse_site_url}`} target="_blank" rel="noreferrer">
                    <button
                      className={style.button4}
                      style={{
                        backgroundColor: oddcolor === "casa_empate" ? "#0419E5" : "",
                        color: oddcolor === "casa_empate" ? "#fff" : "",
                      }}
                    >
                      {Number(odd.casa_empate_maior_odd).toFixed(2)}
                    </button>
                  </a>
                )}
                {/* Gols Mais de 1.5 */}
                {odd.casa_fora_maior_odd !== 0 && (
                  <a href={`${odd.bethouse_site_url}`} target="_blank" rel="noreferrer">
                    <button
                      className={style.button5}
                      style={{
                        backgroundColor: oddcolor === "casa_fora" ? "#0419E5" : "",
                        color: oddcolor === "casa_fora" ? "#fff" : "",
                      }}
                    >
                      {Number(odd.casa_fora_maior_odd).toFixed(2)}
                    </button>
                  </a>
                )}
                {odd.empate_fora_maior_odd !== 0 && (
                  <a href={`${odd.bethouse_site_url}`} target="_blank" rel="noreferrer">
                    <button
                      className={style.button5}
                      style={{
                        backgroundColor: oddcolor === "empate_fora" ? "#0419E5" : "",
                        color: oddcolor === "empate_fora" ? "#fff" : "",
                      }}
                    >
                      {Number(odd.empate_fora_maior_odd).toFixed(2)}
                    </button>
                  </a>
                )}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default OddDetails;
