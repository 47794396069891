import React from "react";
import { Formik, Form } from "formik";
import style from "./style.module.css";
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import { auth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from '../../components/firebase/firebase';
import { useNavigate } from "react-router-dom";
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import FacebookIcon from '@mui/icons-material/Facebook';

const Cadastro = () => {
  const navigate = useNavigate();

  const salvarinfo = async (uid, email, nome) => {
    try {
      // Salvar informações no backend
    } catch (error) {
      console.error("Erro ao salvar informações", error);
    }
  };

  const loginwithgoogle = async () => {
    try {
      // Lógica de login com Google
    } catch (error) {
      console.error("Erro no login com Google", error);
    }
  };

  return (
    <div className={style.main}>
      <div className={style.header}>
        <img src={require('../../assets/logoheader.png')} alt="Logo" />
        <Button variant="outlined" onClick={() => navigate('/')} style={{ padding: '20px', minWidth: '150px', height: '50px', color: 'white', fontSize: '12px', padding: '7px', borderColor: '#2BE28F' }}>
          Login
        </Button>
      </div>
      <div className={style.form}>
        <div className={style.divAvatar}>
          <h1 style={{ fontSize: '23px', color: '#2BE28F' }}>Não tem uma conta?</h1>
          <h1 style={{ fontSize: '30px' }}>Cadastre sua Conta</h1>
        </div>
        <Formik
          initialValues={{ email: '', password: '', confirmPassword: '' }}
          validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email = 'O e-mail é obrigatório';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = 'Endereço de e-mail inválido';
            }
            if (!values.password) {
              errors.password = 'A senha é obrigatória';
            }
            if (!values.confirmPassword) {
              errors.confirmPassword = 'A confirmação da senha é obrigatória';
            } else if (values.password !== values.confirmPassword) {
              errors.confirmPassword = 'As senhas não coincidem';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            createUserWithEmailAndPassword(auth, values.email, values.password)
              .then(user => {
                salvarinfo(user.user.uid, values.email, values.email.substring(0, 5));
                signInWithEmailAndPassword(auth, values.email, values.password)
                  .then(() => navigate('/'))
                  .catch(error => console.error(error));
              })
              .catch(error => {
                alert('Email já cadastrado ou senha muito fraca');
              });
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, values, handleChange, handleBlur, touched, errors }) => (
            <Form>
              <div className={style.textfield}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="E-mail"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  margin="dense"
                />
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  label="Senha"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  margin="dense"
                />
                <TextField
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Confirmar Senha"
                  type="password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  margin="dense"
                  style={{marginBottom: '20px'}}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'collum', width: '100%'}}>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  style={{
                    backgroundColor: isSubmitting ? '#6c757d' : '#212529',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '20px',
                    width: '100%',
                    borderRadius: '8px',
                    minWidth: 'none',
                    maxWidth: 'none',
                    cursor: isSubmitting ? 'not-allowed' : 'pointer',
                    transition: 'background-color 0.3s ease'
                  }}    
                  onMouseEnter={(e) => {
                    if (!isSubmitting) {
                      e.target.style.backgroundColor = '#343a40';
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (!isSubmitting) {
                      e.target.style.backgroundColor = '#212529';
                    }
                  }}
                  onMouseDown={(e) => {
                    if (!isSubmitting) {
                      e.target.style.backgroundColor = '#495057';
                    }
                  }}
                  onMouseUp={(e) => {
                    if (!isSubmitting) {
                      e.target.style.backgroundColor = '#212529';
                    }
                  }}
                  variant="contained"
                >
                  CONTINUE
                </button>
              </div>

              {/* <div className={style.divconexoes}>
                <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginBottom: '20px'}}>
                  <hr style={{ height: '0.5px', width: '90%', backgroundColor: 'black'}}/>
                  <p style={{ margin: '10px'}}>or</p>
                  <hr style={{ height: '0.5px', width: '90%', backgroundColor: 'black'}}/>
                </div>
                <div style={{ display: 'flex' ,gap: '0.5rem'}}>
                  <GoogleIcon onClick={loginwithgoogle} style={{ marginBottom: '16px', backgroundColor: '#0043b6', borderRadius: '50%', padding: '10px', cursor: 'pointer', color: 'white' }} />
                  <FacebookIcon style={{ marginBottom: '16px', backgroundColor: '#0043b6', borderRadius: '50%', padding: '10px', cursor: 'pointer', color: 'white' }} />
                  <AppleIcon style={{ marginBottom: '16px', backgroundColor: '#0043b6', borderRadius: '50%', padding: '10px', cursor: 'pointer', color: 'white' }} />
                </div>
              </div> */}
            </Form>
          )}
        </Formik>
      </div>
      <div className={style.footer} />
    </div>
  );
};

export default Cadastro;
