// src/assets/betLogos.js
import bet365 from "./bet365.png";
import betano from "./Betano.webp";
import betway from "./betway.png";
import rivalry from "./rivalry.png";
import sportingbet from "./sportingbet.png";
import vaidebet from "./vaidebet.png";
import pixbet from "./pixbet.png";
import x1bet from "./1xbet.png";
import casadeapostas from "./image.png";
import betmais from "./betmais.png";
import esportivabet from "./esportivabet.png";
import brisabet from "./brisabet.png";
import kto_group from "./kto.png";
import brazino from "./brazino.webp";
import sorte_Online from "./sorteonline.png";
import bet7k from "./bet7k.png";
import betvip from "./betvip.png";


const betLogos = {
  bet365,
  betano,
  betway,
  rivalry,
  sportingbet,
  vaidebet,
  pixbet,
  x1bet,
  casadeapostas,
  betmais,
  esportivabet,
  brisabet,
  kto_group,
  brazino,
  bet7k,
  betvip,
  sorte_Online
};

export default betLogos;
