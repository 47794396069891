// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OptionSelect_dropdownRow__-78KB {
  display: flex;
  align-items: center;
  padding: 10px;
}

.OptionSelect_dropdownRow__-78KB p {
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/OptionSelect/OptionSelect.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".dropdownRow {\n  display: flex;\n  align-items: center;\n  padding: 10px;\n}\n\n.dropdownRow p {\n  font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownRow": `OptionSelect_dropdownRow__-78KB`
};
export default ___CSS_LOADER_EXPORT___;
