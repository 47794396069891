// src/components/OddCard.js
import React from "react";
import style from "./OddCard.module.css";

const OddCard = ({ odd, selectedOption, setOdd, Setidpag }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
};

  return (
    <div
      style={{
        display:
          (selectedOption === "option2" &&
            odd.ambos_marcam_sim_maior_odd === 0) ||
          (selectedOption === "option3" && odd.casa_empate_maior_odd === 0)
            ? "none"
            : "",
      }}
    >
      {selectedOption == "option2" ? (
        <div className={style.bets}>
          <img
            src={odd.timeCasa.logo_url}
            alt="Team Logo"
            className={style.teamLogo}
          />
          <img
            src={odd.timeFora.logo_url}
            alt="Team Logo"
            className={style.teamLogo}
          />

          <div className={style.times} onClick={() => Setidpag(odd)}>
            <p className={style.horario}>{formatDate(odd.datahora)}</p>
            <p>{odd.time_casa}</p>
            <p className={style.nomeFora}>{odd.time_fora}</p>
          </div>
          <div className={style.odds}>
            <img
              src={odd.timeCasa.logo_url}
              alt="Team Logo"
              className={style.teamLogo2casa}
            />
            <img
              src={odd.timeFora.logo_url}
              alt="Team Logo"
              className={style.teamLogo2fora}
            />
          </div>
          <div className={style.titulo}>
            <div className={style.divButton}>
              <p>sim</p>
              
              <button
                onClick={() => setOdd(odd.jogo_id, "ambos_marcam_sim")}
                className={style.button2}
              >
                {Number(odd.ambos_marcam_sim_maior_odd).toFixed(2)}
              </button>
            </div>
            <div className={style.divButton}>
              <p>não</p>
              <button
                onClick={() => setOdd(odd.jogo_id, "ambos_marcam_nao")}
                className={style.button2}
              >
                {Number(odd.ambos_marcam_nao_maior_odd).toFixed(2)}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {selectedOption == "option1" ? (
            <div className={style.bets}>
              <img
                src={odd.timeCasa.logo_url}
                alt="Team Logo"
                className={style.teamLogo}
              />
              <img
                src={odd.timeFora.logo_url}
                alt="Team Logo"
                className={style.teamLogo}
              />
              <div className={style.times} onClick={() => Setidpag(odd)}>
                <p className={style.horario}>{formatDate(odd.datahora)}</p>
                <p>{odd.time_casa}</p>
                <p className={style.nomeFora}>{odd.time_fora}</p>
              </div>
              <div className={style.odds}>
                <img
                  src={odd.timeCasa.logo_url}
                  alt="Team Logo"
                  className={style.teamLogo2casa}
                />
                <img
                  src={odd.timeFora.logo_url}
                  alt="Team Logo"
                  className={style.teamLogo2fora}
                />
              </div>
              <div className={style.titulo}>
                <div className={style.divButton}>
                  <p>casa</p>
                  <button
                    onClick={() => setOdd(odd.jogo_id, "casa")}
                    className={style.button2}
                  >
                    {Number(odd.casa_maior_odd).toFixed(2)}
                  </button>
                </div>
                <div className={style.divButton}>
                  <p>empate</p>
                  <button
                    onClick={() => setOdd(odd.jogo_id, "empate")}
                    className={style.button2}
                  >
                    {Number(odd.empate_maior_odd).toFixed(2)}
                  </button>
                </div>
                <div className={style.divButton}>
                  <p>fora</p>
                  <button
                    onClick={() => setOdd(odd.jogo_id, "fora")}
                    className={style.button2}
                  >
                    {Number(odd.fora_maior_odd).toFixed(2)}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className={style.bets}>
              <img
                src={odd.timeCasa.logo_url}
                alt="Team Logo"
                className={style.teamLogo}
              />
              <img
                src={odd.timeFora.logo_url}
                alt="Team Logo"
                className={style.teamLogo}
              />
              <div className={style.times} onClick={() => Setidpag(odd)}>
                <p className={style.horario}>{formatDate(odd.datahora)}</p>
                <p>{odd.time_casa}</p>
                <p className={style.nomeFora}>{odd.time_fora}</p>
              </div>
              <div className={style.odds}>
                <img
                  src={odd.timeCasa.logo_url}
                  alt="Team Logo"
                  className={style.teamLogo2casa}
                />
                <img
                  src={odd.timeFora.logo_url}
                  alt="Team Logo"
                  className={style.teamLogo2fora}
                />
              </div>
              <div className={style.titulo}>
                <div className={style.divButton}>
                  <p style={{ fontSize: '9px'}}>Casa ou Empate</p>
                  <button
                    onClick={() => setOdd(odd.jogo_id, "casa_empate")}
                    className={style.button2}
                  >
                    {Number(odd.casa_empate_maior_odd).toFixed(2)}
                  </button>
                </div>
                <div className={style.divButton}>
                  <p style={{ fontSize: '9px'}}>Casa ou Fora</p>
                  <button
                    onClick={() => setOdd(odd.jogo_id, "casa_fora")}
                    className={style.button2}
                  >
                    {Number(odd.casa_fora_maior_odd).toFixed(2)}
                  </button>
                </div>
                <div className={style.divButton}>
                  <p style={{ fontSize: '9px'}}>Fora ou Empate</p>
                  <button
                    onClick={() => setOdd(odd.jogo_id, "empate_fora")}
                    className={style.button2}
                  >
                    {Number(odd.empate_fora_maior_odd).toFixed(2)}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OddCard;
