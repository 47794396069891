import React, { useEffect, useState, useContext } from "react";
import style from "./style.module.css";
import Footer from "../../components/Footer/index";
import Lateral from "../../components/Lateral/index";
import api from "../../components/api/api";
import logo from "../../assets/logofooter.png";
import ad from "../../assets/3.png";
import { useNavigate } from "react-router-dom";
import { OddsContext } from "../../components/context";
import {
  auth,
  onAuthStateChanged,
  analytics,
} from "../../components/firebase/firebase";
import { logEvent } from "firebase/analytics";
import Carousel from "../../components/Carrossel/Carousel";
import OddCard from "../../components/OddCard/OddCard";
import Loading from "../../components/Loading/Loading";
import OptionSelect from "../../components/OptionSelect/OptionSelect";
import OddDetails from "../../components/OddDetails/OddDetails";
import carrossel1 from "../../assets/BANNER_1.png";
import carrossel2 from "../../assets/BANNER_2.png";
import carrossel3 from "../../assets/BANNER_3.png";

export default function Main() {
  const navigate = useNavigate();
  const { atualizaodd } = useContext(OddsContext);
  const [oddClicada, setOddClicada] = useState({});
  const [oddon, setOddon] = useState([]);
  const [idodd, setIdodd] = useState("");
  const [idoddantiga, setIdoddantiga] = useState("");
  const [idoddantiga2, setIdoddantiga2] = useState("");
  const [oddcolor, setOddcolor] = useState("");
  const [selectedOption, setSelectedOption] = useState("option1");
  const [isLoading, setIsLoading] = useState(true);

  const data = [
    { id: "1", image: carrossel3 },
    { id: "2", image: carrossel2 },
    { id: "3", image: carrossel1 },
  ];

  // Função para lidar com o clique de um link
  const handleClick = (link) => logEvent(analytics, link);

  // Função para verificar autenticação
  const checkAuth = () => {
    onAuthStateChanged(auth, (user) => {
      if (!user) navigate("/login");
    });
  };

  // Função para lidar com a alteração de opção selecionada
  const handleOptionChange = (event) => setSelectedOption(event.target.value);

  // Função para conectar WebSocket
  // const connectWebSocket = () => {
  //   let ws = new WebSocket("wss://googolbet.com.br/ws/", [], 0);

  //   ws.onclose = (event) => {
  //     if (event.code === 1006) {
  //       setTimeout(connectWebSocket, 2000);
  //     }
  //   };

  //   ws.onmessage = () => {
  //     setTimeout(getOdds, 10000);
  //   };
  // };

  // Função para atualizar a odd
  const setOdd = (odd, tipo) => {
    setOddcolor(tipo);
    if (odd === idoddantiga && tipo === idoddantiga2) {
      resetOdd();
    } else {
      updateOdd(odd, tipo);
    }
  };

  const resetOdd = () => {
    setIdodd("");
    setIdoddantiga("");
    setIdoddantiga2("");
  };

  const updateOdd = (odd, tipo) => {
    setIdodd(odd);
    setIdoddantiga(odd);
    setIdoddantiga2(tipo);
    console.log(odd);
    console.log(oddClicada);
    console.log(filterByKey(oddClicada, tipo));
    setOddClicada(filterByKey(oddClicada, tipo));
    getOddEspecial(odd, tipo);
  };

  function filterByKey(data, key) {
    if (!data[key]) {
      console.error(`Key "${key}" not found in data`);
      return [];
    }
    return data[key];
  } // Função para buscar odds especiais
  const getOddEspecial = async (id, tipoodd) => {
    try {
      const response = await api.get(`/jogos/${id}/`);
      console.log(response.data.data);
      setOddClicada(filterByKey(response.data.data, tipoodd));
    } catch (error) {
      window.alert("Erro ao buscar odds especiais.");
    }
  };

  // Função para buscar odds
  // Função para buscar odds
  const getOdds = async () => {
    try {
      setIsLoading(true);
      const response = await api.get(`/jogos`);

      // Ordena as odds pela datahora
      const sortedOdds = response.data.data.sort(
        (a, b) => new Date(a.datahora) - new Date(b.datahora)
      );

      setOddon(sortedOdds);
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao buscar jogos.");
    }
  };

  // Função para configurar id da página
  const setIdPag = (id) => {
    localStorage.setItem("id", id.jogo_id);
    localStorage.setItem("nomecasa", id.time_casa);
    localStorage.setItem("nomefora", id.time_fora);
    localStorage.setItem("logocasa", id.timeCasa.logo_url);
    localStorage.setItem("logofora", id.timeFora.logo_url);
    navigate("/bet");
  };

  // useEffect Hooks
  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    getOdds();
  }, []);
  useEffect(() => {
    resetOdd();
  }, [selectedOption]);

  useEffect(() => {
    getOdds();
  }, [atualizaodd, selectedOption]);

  // useEffect(() => {
  //   const ws = connectWebSocket();

  //   // Retorne a função de cleanup para garantir que o WebSocket seja fechado corretamente
  //   return () => {
  //     if (ws && ws.close) {
  //       ws.close();
  //     }
  //   };
  // }, []);

  return (
    <div style={{ borderRadius: "15px" }}>
      <div className={style.divcarrossel}>
        <Carousel data={data} />
      </div>

      {/* <div className={style.fantasy}>
        <img src={logo} alt="Logo" />
        <h1>A mais nova plataforma de Fantasy Gaming</h1>
        <h1>Aguardem!</h1>
      </div> */}

      <div className={style.geral}>
        <Lateral />
        <div className={style.main}>
          {isLoading ? <Loading /> : null}
          <div className={style.classificar}>
            {oddon.length > 0 ? (
              <OptionSelect
                selectedOption={selectedOption}
                handleOptionChange={handleOptionChange}
              />
            ) : (
              <h1>Não há jogos no momento para essa liga.</h1>
            )}
          </div>
          <div className={style.jogos}>
            {oddon.map((odd, index) => (
              <div key={index}>
                <OddCard
                  odd={odd}
                  selectedOption={selectedOption}
                  setOdd={setOdd}
                  Setidpag={setIdPag}
                />
                {odd.jogo_id === idodd && ( ///nao faz muito sentido
                  <OddDetails
                    oddClicada={oddClicada}
                    oddcolor={oddcolor}
                    HandleClick={handleClick}
                    selectedOption={selectedOption}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className={style.ad}>
          <img src={ad} alt="ad" />
        </div>
      </div>

      <Footer />
    </div>
  );
}
