import React from "react";
import { Formik, Form} from "formik";
import style from "./style.module.css";
import { Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import {auth, signInWithEmailAndPassword, signInWithPopup, googleProvider} from '../../components/firebase/firebase'
import { useNavigate } from "react-router-dom";
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import FacebookIcon from '@mui/icons-material/Facebook';
import api from '../../components/api/api'
import { CheckBox } from "@mui/icons-material";
import logo from '../../assets/logoheader.png'

const Login = () => {
  const navigate = useNavigate();
  const salvarinfo = async (uid, email, nome) => {
    console.log(uid, email, nome)
    try {
      const data = await api.post('/firebase/addUser', {
        uid: uid,
        email: email,
        nome: nome
      })
      .then(() => {
        console.log('Usuário cadastrado com sucesso');
      }
      )
    } catch (error) {
      // Handle the error here
    }
  }
  const loginwithgoogle = async (auth, googleProvider) => {
    try {
      await signInWithPopup(auth, googleProvider)
      .then((user) => {
        salvarinfo(user.user.uid, user.user.email, user.user.displayName)
        navigate('/');
      })
      .catch((error) => {
      });
    } catch (error) {
    }
  }
  return (
    <div className={style.main}>
      <div className={style.header}>
        <img src={logo}/>
        <Button variant="outlined" onClick={() => navigate('/cadastro')} style={{ padding: '20px' ,minWidth: '150px', height: '50px', color: 'white', fontSize: '12px', padding: '7px', borderColor: '#2BE28F'}}>
          Registre-se
        </Button>
      </div>
      <div className={style.form}>
        <div className={style.divAvatar}>
          <h1 style={{ fontSize: '23px', color: '#2BE28F'}}>Bem Vindo!</h1>
          <h1 style={{ fontSize: '30px'}}>Entre em sua Conta</h1>
        </div>
        <Formik
      initialValues={{ email: '', password: '' }}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'O e-mail é obrigatório';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Endereço de e-mail inválido';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          try {
            signInWithEmailAndPassword(auth, values.email, values.password)
              .then(() => {
                navigate('/');
              })
              .catch((error) => {
                alert('E-mail ou senha inválidos');
              });
          } catch (error) {
            // Handle error
          }
          setSubmitting(false);
        }, 400);
      }}
    >
      {({ isSubmitting, values, handleChange, handleBlur, touched, errors }) => (
        <Form>
          <div className={style.textfield}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="E-mail"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            margin="dense"
          />
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Senha"
            type="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
            margin="dense"
          />
          </div>
          <div className={style.divider}>
          <div className={style.conta}>
            <div style={{ display: 'flex', alignItems: 'center'}}>
            <CheckBox style={{ color: '#2BE28F' }}/>
            <p style={{ fontSize: '12px'}}>Remember me</p>
            </div>
            <p onClick={() => navigate('/recuperar')} style={{ cursor: 'pointer', color: '#2BE28F' }}>
                Esqueceu a senha?
            </p>
          </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'collum', width: '100%'}}>
          <button
            type="submit"
            disabled={isSubmitting}
            style={{
              backgroundColor: isSubmitting ? '#6c757d' : '#212529',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              padding: '20px',
              width: '100%',
              borderRadius: '8px',
              minWidth: 'none',
              maxWidth: 'none',
              cursor: isSubmitting ? 'not-allowed' : 'pointer',
              transition: 'background-color 0.3s ease'
            }}    
            onMouseEnter={(e) => {
              if (!isSubmitting) {
                e.target.style.backgroundColor = '#343a40';
              }
            }}
            onMouseLeave={(e) => {
              if (!isSubmitting) {
                e.target.style.backgroundColor = '#212529';
              }
            }}
            onMouseDown={(e) => {
              if (!isSubmitting) {
                e.target.style.backgroundColor = '#495057';
              }
            }}
            onMouseUp={(e) => {
              if (!isSubmitting) {
                e.target.style.backgroundColor = '#212529';
              }
            }}
            variant="contained"
          >
            CONTINUE
          </button>
          </div>

        {/* <div className={style.divconexoes}>
          <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginBottom: '20px'}}>
          <hr style={{ height: '0.5px', width: '90%', backgroundColor: 'black'}}/>
          <p style={{ margin: '10px'}}>or</p>
          <hr style={{ height: '0.5px', width: '90%', backgroundColor: 'black'}}/>
          </div>
          <div style={{ display: 'flex' ,gap: '0.5rem'}}>
                  <GoogleIcon onClick={loginwithgoogle} style={{ marginBottom: '16px', backgroundColor: '#0043b6', borderRadius: '50%', padding: '10px', cursor: 'pointer', color: 'white' }} />
                  <FacebookIcon style={{ marginBottom: '16px', backgroundColor: '#0043b6', borderRadius: '50%', padding: '10px', cursor: 'pointer', color: 'white' }} />
                  <AppleIcon style={{ marginBottom: '16px', backgroundColor: '#0043b6', borderRadius: '50%', padding: '10px', cursor: 'pointer', color: 'white' }} />
                </div>
        </div> */}
        </Form>
      )}
    </Formik>
        
      </div>
      <div className={style.footer}/>

    </div>
  );
};

export default Login;