import React from "react";
import { Formik, Form} from "formik";
import style from "./style.module.css";
import { Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import {auth,sendPasswordResetEmail} from '../../components/firebase/firebase'
import { useNavigate } from "react-router-dom";

const Recuperar = () => {
  const navigate = useNavigate();

  return (
    <div className={style.main}>
      <div className={style.header}>
        <img src={require('../../assets/logoheader.png')} alt="Logo" />
        <Button variant="outlined" onClick={() => navigate('/')} style={{ padding: '20px', minWidth: '150px', height: '50px', color: 'white', fontSize: '12px', padding: '7px', borderColor: '#2BE28F' }}>
          Login
        </Button>
      </div>
      <div className={style.form}>
        <div className={style.divAvatar}>
          <h1 style={{ fontSize: '23px', color: '#2BE28F' }}>Esqueceu sua senha?</h1>
          <h1 style={{ fontSize: '30px' }}>Recupere sua Conta</h1>
        </div>
        <Formik
      initialValues={{ email: '' }}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'O e-mail é obrigatório';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Endereço de e-mail inválido';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          try {
            sendPasswordResetEmail(auth, values.email)
              .then(() => {
                alert("E-mail de recuperação de senha enviado com sucesso.")
              })
              .catch((error) => {
                alert(error)
              });
          } catch (error) {
            alert(error)
          }
          setSubmitting(false);
        }, 400);
      }}
    >
      {({ isSubmitting, values, handleChange, handleBlur, touched, errors }) => (
            <Form>
            <div className={style.textfield}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="E-mail"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              margin="dense"
            />
            </div>
            
            <div className={style.divider}>
              <button type="submit" disabled={isSubmitting} style={{
              backgroundColor: isSubmitting ? '#6c757d' : '#212529',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              padding: '20px',
              width: '100%',
              borderRadius: '8px',
              minWidth: 'none',
              maxWidth: 'none',
              cursor: isSubmitting ? 'not-allowed' : 'pointer',
              transition: 'background-color 0.3s ease'
            }}    
            onMouseEnter={(e) => {
              if (!isSubmitting) {
                e.target.style.backgroundColor = '#343a40';
              }
            }}
            onMouseLeave={(e) => {
              if (!isSubmitting) {
                e.target.style.backgroundColor = '#212529';
              }
            }}
            onMouseDown={(e) => {
              if (!isSubmitting) {
                e.target.style.backgroundColor = '#495057';
              }
            }}
            onMouseUp={(e) => {
              if (!isSubmitting) {
                e.target.style.backgroundColor = '#212529';
              }
            }}
            variant="contained">
                Enviar
              </button>
              
            </div>
  
            <div className={style.conta}>
            <p onClick={() => navigate('/Login')} style={{ cursor: 'pointer', color: '#2BE28F' }}>
                Já tem uma conta?
            </p>
            </div>
            
          </Form>
          )}
        </Formik>
      </div>
      <div className={style.footer} />
    </div>
  );
};

export default Recuperar;
