// src/components/Loading.js
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import style from "./Loading.module.css";

const Loading = () => (
  <div className={style.loadingBar}>
    <CircularProgress />
  </div>
);

export default Loading;
