// src/components/OptionSelect.js
import React from "react";
import style from "./OptionSelect.module.css";

const OptionSelect = ({ selectedOption, handleOptionChange }) => (
  <div className={style.dropdownRow}>
    <p>Classificar por: </p>
    <select value={selectedOption} onChange={handleOptionChange}>
      <option value="option1">Resultado 1X2</option>
      <option value="option2">Ambas as equipes marcam</option>
      <option value="option3">Dupla chance</option>
    </select>
  </div>
);

export default OptionSelect;
