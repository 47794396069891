// src/components/Carousel.js
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/swiper-bundle.css";
import "swiper/css/effect-fade";
import style from "./Carousel.module.css";

const Carousel = ({ data }) => (
  <Swiper
    modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
    slidesPerView={1}
    pagination={{ clickable: true }}
    loop={true}
    style={{ width: "100%", height: "100%" }}
    navigation={{
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    }}
  >
    {data.map((item) => (
      <SwiperSlide key={item.id}>
        <img src={item.image} alt="Slider" className={style.sliderItem} />
      </SwiperSlide>
    ))}

    <div className="swiper-button-prev"></div>
    <div className="swiper-button-next"></div>
  </Swiper>
);

export default Carousel;
