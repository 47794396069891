// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Carousel_sliderItem__DcG\\+5{
    object-fit: cover;
    display: flex;
    width: 55%;
    border-radius: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Carrossel/Carousel.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,UAAU;IACV,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":[".sliderItem{\n    object-fit: cover;\n    display: flex;\n    width: 55%;\n    border-radius: 20px;\n    margin-top: 40px;\n    margin-bottom: 40px;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sliderItem": `Carousel_sliderItem__DcG+5`
};
export default ___CSS_LOADER_EXPORT___;
