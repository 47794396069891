import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import logo from "../../assets/logoheader.png";
import style from "./style.module.css";
import api from "../api/api";
import { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { OddsContext } from "../context";
import { FaBars } from "react-icons/fa";
import Sidebar from "../Sidebar";
import { Button } from "@mui/material";
import { auth, signOut } from "../../components/firebase/firebase";

export default function SearchAppBar() {
  const [sidebar, setSidebar] = useState(false);
  const showSiderbar = () => setSidebar(!sidebar);
  const navigate = useNavigate();
  const [pesquisa, setPesquisa] = useState("");
  const [oddon, setOddon] = React.useState([]);
  const [delay, setDelay] = React.useState(null);
  const { atualizaodd, setAtualizaodd } = React.useContext(OddsContext);
  const Setidpag = (id) => {
    if (!localStorage.getItem("liga")) {
      localStorage.setItem("liga", "?liga=71");
    } else {
      localStorage.removeItem("liga");
    }
    setAtualizaodd(localStorage.getItem("liga"));
    localStorage.setItem("id", id.jogo_id);
    localStorage.setItem("nomecasa", id.time_casa);
    localStorage.setItem("nomefora", id.time_fora);
    localStorage.setItem("logocasa", id.timeCasa.url_casa);
    localStorage.setItem("logofora", id.timeFora.url_fora);
    navigate("/bet");
  };
  const busca = async () => {
    if (pesquisa === "") {
      return; // Exit the function if the search string is empty
    }

    try {
      if (pesquisa && pesquisa !== "") {
        // Check if the search query is not empty
        const response = await api
          .post(`/jogos/busca/nome`, { nomeTime: pesquisa.toLowerCase() })
          .then((response) => {
            setOddon(response.data.data);
          })
          .catch(function (error) {
            window.alert("deu erro.");
          });
        return response;
      }
    } catch (error) {
      throw error;
    }
  };
  React.useEffect(() => {
    if (!localStorage.getItem("liga")) {
      localStorage.setItem("liga", "?liga=71");
    }
  }, []);
  const changeLeague = (liga) => {
    console.log(liga);
    localStorage.setItem("liga", liga);
    setAtualizaodd(`${liga}`);
    navigate("/");
  };

  const handleSearch = (event) => {
    const selectedOption = oddon.find(
      (option) =>
        `${option.time_casa} x ${option.time_fora}` === event.target.value
    );
    if (selectedOption) {
      Setidpag(selectedOption);
    }
    clearTimeout(delay);
    setDelay(
      setTimeout(() => {
        setPesquisa(event.target.value);
        busca(event.target.value);
      }, 200)
    );
  };
  return (
    <div className={style.divgeral}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar sx={{ backgroundColor: "#0118E6" }}>
            {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
            <div className={style.menu}>
              <FaBars onClick={showSiderbar} />
              {sidebar && <Sidebar active={setSidebar} />}
            </div>
            <img
              src={logo}
              alt="Logo"
              className={style.logo}
              onClick={() => navigate("/")}
            />
            <div className={style.navegacao}>
              <a
                className={style.inicio}
                onClick={() => changeLeague("?liga=71")}
              >
                Brasileirão A
              </a>
              {/* <a
                className={style.inicio}
                onClick={() => changeLeague("?liga=72")}
              >
                Brasileirão B
              </a> */}
              {/* /jogos?liga=71 */}
              {/* <a
                className={style.inicio}
                onClick={() => changeLeague("?liga=39")}
              >
                Premier
              </a>{" "} */}
              {/* /jogos?liga=13 */}
            </div>

            <div className={style.barrasearch}>
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={oddon.map(
                  (option) => `${option.time_casa} x ${option.time_fora}`
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Buscar..."
                    variant="filled"
                    size="small"
                    className={style.searchbar}
                    sx={{
                      backgroundColor: "#ffffff",
                      borderRadius: "5px",
                      color: "#000000",
                    }}
                    margin="normal"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                      onChange: handleSearch,
                    }}
                  />
                )}
                onChange={(event, value) => {
                  handleSearch({ target: { value } });
                }}
              />
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}
