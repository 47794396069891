import React from "react";
import { Container, Content } from "./styles";
import { useNavigate } from "react-router-dom";
import { OddsContext } from "../context";
import { auth, signOut } from "../../components/firebase/firebase";
import {
  FaTimes,
  FaHome,
  FaEnvelope,
  FaRegSun,
  FaUserAlt,
  FaIdCardAlt,
  FaRegFileAlt,
  FaRegCalendarAlt,
  FaChartBar,
} from "react-icons/fa";
import { GiSoccerBall } from "react-icons/gi";

import SidebarItem from "../SidebarItem";

const Sidebar = ({ active }) => {
  const closeSidebar = () => {
    active(false);
  };
  const { atualizaodd, setAtualizaodd } = React.useContext(OddsContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    localStorage.setItem("liga", "?liga=71");
  }, []);
  const changeLeague = (liga) => {
    console.log("oi");
    localStorage.setItem("liga", liga);
    setAtualizaodd(`${liga}`);
    navigate("/");
  };

  return (
    <Container sidebar={active}>
      <FaTimes onClick={closeSidebar} />
      <Content>
        <div onClick={() => signOut(auth)}>
          <SidebarItem Icon={FaUserAlt} Text="Logout" />
        </div>
        <div onClick={() => changeLeague("?liga=71")}>
          <SidebarItem Icon={GiSoccerBall} Text="Brasileirão A" />
        </div>
        {/* <div onClick={() => changeLeague("?liga=72")} ><SidebarItem Icon={GiSoccerBall} Text="Brasileirão B" /></div>
        <div onClick={() => changeLeague("?liga=39")} ><SidebarItem Icon={GiSoccerBall} Text="Premier" /></div> */}
        {/* <SidebarItem Icon={FaHome} Text="Inicio" /> */}
      </Content>
    </Container>
  );
};

export default Sidebar;
